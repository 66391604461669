import styled from "@emotion/styled";
import { Text } from "components";
import { Checkmark } from "components/icons";
import React, { useCallback, useEffect } from "react";
import * as crypto from "crypto-js";
import { theme } from "assets/theme";
import { css } from "@emotion/css";
import { extractFromQuery } from "utils/helpers";

export const DONE_MESSAGE = "done";

const IconWrapper = styled.div`
  align-items: center;
  background: linear-gradient(
    60deg,
    ${({ theme }) => theme.colors.accent["blue/main"]},
    ${({ theme }) => theme.colors.accent["purple/light"]},
    ${({ theme }) => theme.colors.accent["pink/main"]}
  );
  border-radius: 150px;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 150px;
`;

type Params = {
  app?: "slack" | "github";
  installed?: string;
  source?: "desktop" | "web";
  data?: string;
};
export function Done() {
  // We may end up on this page after a Google OAuth redirect. In the case
  // where that Google OAuth login took place inside of a popup window, we
  // want to close that window. So, we postMessage to a potential parent
  // window.
  const { app, installed, source, data } = extractFromQuery<Params>(
    window.location.search
  );

  const openDesktopApp = useCallback(() => {
    let destination = "";

    if (source && data) {
      const decryptedData = crypto.AES.decrypt(
        data,
        process.env.REACT_APP_SECRET_KEY!
      ).toString(crypto.enc.Utf8);
      const userData = JSON.parse(decryptedData);
      destination = `/auth/continue?data=${encodeURIComponent(
        JSON.stringify(userData.token)
      )}`;
    } else {
      destination = "/done";
    }
    let a = document.createElement("a");
    a.href = `multiplayer://app${destination}`;
    a.click();
  }, [source, data]);

  useEffect(() => {
    if (source === "desktop") {
      openDesktopApp();

      if (!data) {
        // internal integrations
        setTimeout(() => {
          window.close();
        }, 2000);
      }

      // a.addEventListener('click', () => {
      //   window.close()
      // });
    }
  }, [data, openDesktopApp, source]);

  useEffect(() => {
    if (typeof window !== "undefined" && (!app || installed === "true")) {
      window.opener?.postMessage(DONE_MESSAGE);
      window.postMessage(DONE_MESSAGE);
      window.parent?.postMessage(DONE_MESSAGE);
    }
  }, [app, installed]);

  return (
    <div
      className={css`
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <IconWrapper>
        <Checkmark
          style={{
            height: "100px",
            fill: "red",
            stroke: theme.colors.primary["primary/01"],
            strokeWidth: "4px",
          }}
        />
      </IconWrapper>
      <Text.H3>App installed successfully</Text.H3>

      <Text.Body>You can close this window</Text.Body>
    </div>
  );
}
