const RoobertRegular = "Roobert-Regular";
const RoobertMedium = "Roobert-Medium";

export const font = {
  h1: {
    fontSize: "38px",
    fontWeight: 500,
    lineHeight: "45.6px",
    fontFamily: RoobertMedium,
  },
  h2: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "28.8px",
    fontFamily: RoobertMedium,
  },
  h3: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    fontFamily: RoobertMedium,
  },
  h4: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
    fontFamily: RoobertMedium,
  },
  h5: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: RoobertMedium,
  },
  h6: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    fontFamily: RoobertMedium,
  },
  body: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26.6px",
    fontFamily: RoobertRegular,
  },
  "body-sm": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.2px",
    fontFamily: RoobertRegular,
  },
  "body-xs": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    fontFamily: RoobertMedium,
  },
  "body-2xs": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.8px",
    fontFamily: RoobertRegular,
  },
  "body-3xs": {
    fontSize: "8px",
    fontWeight: 400,
    lineHeight: "14.4px",
    fontFamily: RoobertRegular,
  },
  button: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.2px",
    fontFamily: RoobertRegular,
  },
};
