import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNewToken } from "services";
import { E_Route } from "utils/constants";

export async function useAuth() {
  const navigate = useNavigate();

  useEffect(() => {
    getNewToken().then((token) => {
      if (!token) {
        navigate(E_Route.AuthBegin);
      }
    });
  }, [navigate]);
}
