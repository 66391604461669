import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import { AppRoutes } from "routes";
import { ENotificationKey } from "utils/constants";
import pubsub from "sweet-pubsub";

function App() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showCallback = (data: { value: boolean }) => {
      setShow(data.value);
    };
    pubsub.on(ENotificationKey.SHOW_DROPDOWN, showCallback);
    return () => {
      pubsub.off(ENotificationKey.SHOW_DROPDOWN, showCallback);
    };
  }, []);
  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
      `}
    >
      <div
        className={css`
          z-index: -1;
          width: 1200px;
          height: 355.61px;
          flex-shrink: 0;
          border-radius: 905px;
          background: linear-gradient(
            180deg,
            rgba(0, 194, 255, 0) 0%,
            #6a53fe 100%
          );

          filter: blur(98.56096649169922px);
          position: fixed;
          left: calc(1000px / 4);
          top: -430px;
        `}
      />
      {show && (
        <div
          className={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, 0.3);
            inset: 0;
            z-index: 999;
          `}
        />
      )}
      <AppRoutes />
    </div>
  );
}

export default App;
