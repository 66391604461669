import styled from "@emotion/styled";
import { GradientSVG, UploadSimpleIcon } from "components/icons";
import React from "react";


const Download = styled.a`
  display: flex;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #312f48;
  background: #20202f;
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-size: 19px;
  color: #aca7f6;
  position: relative;
  overflow: hidden;
  margin-top: 17px;
  text-decoration: none;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
`;

const Gradient = styled.div`
  position: absolute;
  right: -28px;

  bottom: -44px;
`;

export const DownloadButton: React.FC = () => {
  return (
    <ButtonWrapper>
      <Download href="https://apps.apple.com/us/app/multiplayer-ai/id6642706353" target="_blank" rel="noreferrer">
        <Gradient>
          <GradientSVG />
        </Gradient>
        <UploadSimpleIcon
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Download iOS app
      </Download>
      {/* <ComingSoon>
        <Text.Body2XS>Coming soon</Text.Body2XS>
      </ComingSoon> */}
    </ButtonWrapper>
  );
};