import React from "react";
import { Helmet } from "react-helmet-async";

type PageProps = {
  children: React.ReactNode;
  title?: string;
};
export const PageLayout: React.FC<PageProps> = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title || "Multiplayer AI"}</title>
      </Helmet>
      {children}
    </>
  );
};
