export type UserSettings = {
  hasEmailNotification?: boolean;
  hasSlackNotification?: boolean;
  timeZone?: string;
  language?: string;
  createTaskShortcut?: string;
  createNoteShortcut?: string;
  location?: {
    region?: string;
    city?: string;
    country?: string;
    timezone?: string;
    postal?: string;
  };
};

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  profileUrl?: string;
  email: string;
  created: Date;
  settings?: UserSettings;
}

export enum E_UserAction {
  SET_USER,
  SET_CURRENT_EMAIL,
  CLEAR,
}
