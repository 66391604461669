import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { theme } from "assets/theme";

const max = 20;

const colors = ["#453E69", "#8C83C3", "#8D86F8", "#6A53FE"];

type Props = {
  duration?: number;
  onLoad?: VoidFunction;
};
export const LoadingBar: React.FC<Props> = ({ duration = 3000, onLoad }) => {
  const [background, setBackground] = useState<string[]>(
    _.range(max).map(() => theme.colors.primary["primary/03"])
  );

  useEffect(() => {
    background.forEach((item, index) => {
      setTimeout(() => {
        setBackground((prev) => {
          const newBackground = [...prev];
          newBackground[index] = colors[Math.floor(index / 5)];
          return newBackground;
        });
        if (index === max - 1) {
          onLoad?.();
        }
      }, (duration / max) * index);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={css`
        width: 100%;
        max-width: 549px;

        height: 18px;
        /* border: 1px solid red; */
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(${max}, 1fr);
        gap: 16px;
      `}
    >
      {_.range(max).map((item) => (
        <div
          key={item}
          className={css`
            width: 11.25px;
            height: 100%;
            background-color: ${background[item]};
            border-radius: 8px;
          `}
        />
      ))}
    </div>
  );
};
