import { E_Route } from "utils/constants";
import {
  HomePage,
  LoginPage,
  ContinuePage,
  NotFoundPage,
  Done,
} from "components";

export const PublicPages = [
  {
    path: E_Route.AuthBegin,
    element: <LoginPage />,
  },
  {
    path: E_Route.AuthContinue,
    element: <ContinuePage />,
  },
  //   {
  //     path: E_Route.Media,
  //     element: <Media />,
  //   },
  {
    path: E_Route.Done,
    element: <Done />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export const PrivatePages = [
  {
    path: E_Route.Home,
    element: <HomePage />,
  },
];

// const Pages = PrivatePages.concat(PublicPages);
