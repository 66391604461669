import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { RetryLink } from "@apollo/client/link/retry";
import config from "config/config";
import { E_GraphQlErrorCode } from "types";
import { getNewToken, removeTokens } from "services";

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const httpLink = new HttpLink({
  uri: config.graphQLApiUrl,
});

const wsLink = new WebSocketLink({
  uri: config.wsUrl,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions?.code) {
          case E_GraphQlErrorCode.UNAUTHENTICATED: {
            // to avoid infinite loop
            removeTokens();
            window.location.replace("/auth/login");
            break;
          }
          default: {
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
            );
            break;
          }
        }
      }
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const authLink = setContext(async (_, { headers }) => {
  const token = await getNewToken();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-multiplayer-req-source": "web",
      "x-multiplayer-timezone": timeZone,
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, splitLink, retryLink]),
});
