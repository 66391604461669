import { css } from "@emotion/css";
import * as CDialog from "@radix-ui/react-dialog";
import { theme } from "assets/theme";
import { Text } from "components";
import { Button } from "components";
import { CloseIcon } from "components/icons";
import React, { useEffect, useState } from "react";
import pubsub from "sweet-pubsub";
import { ENotificationKey } from "utils/constants";

type Props = {
  className?: string;
  children?: JSX.Element;
  additionalContent?: React.ReactNode;
  additionalFooterContent?: React.ReactNode;
  containerStyles?: string;
  description?: string;
  open?: boolean;
  submitDisabled?: boolean;
  submitting?: boolean;
  title?: string;
  noRadius?: boolean;
  noClose?: boolean;
  onOpenChange?(newState: boolean): void;
  onSubmit?(onClose: () => void): void;
};

export function Dialog(props: Props) {
  const [open, setOpen] = useState(props.open || false);
  const onClose = () => {
    setOpen(false);
    props.onOpenChange?.(false);
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
  };

  useEffect(() => {
    setOpen(props.open || false);
  }, [props.open]);

  return (
    <CDialog.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        props.onOpenChange?.(open);
        if (!open) {
          pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
        }
      }}
    >
      {props.children && (
        <CDialog.Trigger asChild>
          {React.cloneElement(props.children, {
            // className: cx(props.children.props.className, props.className),
          })}
        </CDialog.Trigger>
      )}
      <CDialog.Portal>
        <CDialog.Overlay
          className={css`
            @keyframes fadeIn {
              from {
                backdrop-filter: blur(0px);
                opacity: 0;
              }
              to {
                backdrop-filter: blur(2px);
                opacity: 1;
              }
            }
            animation: fadeIn 0.15s;
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, 0.3);
            inset: 0;
            position: fixed;
            z-index: 999;
          `}
        />
        <CDialog.Content asChild>
          <form
            className={css`
              @keyframes expand {
                0% {
                  opacity: 0;
                  transform: translateX(-50%) translateY(-50%) scale(0.9);
                }
                100% {
                  opacity: 1;
                  transform: translateX(-50%) translateY(-50%) scale(1);
                }
              }
              animation: expand 0.15s;
              background: linear-gradient(
                54deg,
                #211f30 62.2%,
                #282446 124.68%
              );
              border-radius: 8px;
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
              left: 50%;
              padding: 30px 19px;
              position: fixed;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 1000;
            `}
            onSubmit={(e) => {
              e.preventDefault();

              if (!props.submitDisabled) {
                props.onSubmit?.(onClose);
              }
            }}
          >
            <div
              className={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                position: relative;
                margin-top: -20px;
              `}
            >
              {props.title && (
                <Text.BodyXS color="text/100%" marginBottom={0} marginTop={1}>
                  {props.title}
                </Text.BodyXS>
              )}
              {!props.noClose && (
                <CDialog.Close
                  asChild
                  className={css`
                    background-color: "#2D2A41";
                    /* border: 1px solid red; */
                    height: 24px;
                    width: 24px;
                    margin-top: 5px;
                  `}
                  style={{ backgroundColor: "#2D2A41" }}
                >
                  <button
                    className={css`
                      height: 24px;
                      width: 24px;
                      border-radius: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: "#2D2A41";
                      cursor: pointer;
                    `}
                  >
                    <CloseIcon
                      className={css`
                        height: 12px;
                        width: 12px;
                        fill: ${theme.colors.text["text/blue/soft"]};
                      `}
                    />
                  </button>
                </CDialog.Close>
              )}
            </div>

            {props.description && (
              <CDialog.Description
                className={css`
                  color: ${theme.colors.text["text/light"]};
                  font-size: ${theme.font["body-sm"].fontSize}px;
                  line-height: 1.375rem;
                  margin-bottom: 0;
                  margin-top: 24px;
                `}
              >
                {props.description}
              </CDialog.Description>
            )}
            {props.additionalContent && (
              <article
                className={css`
                  margin-top: 16px;
                `}
              >
                {props.additionalContent}
              </article>
            )}
            <footer
              className={css`
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 16px;
              `}
            >
              {props.additionalFooterContent || <div />}
              {props.onSubmit && (
                <Button

                // loading={props.submitting}
                // disabled={props.submitDisabled}
                // type="submit"
                // customBackground={theme.colors.primary['primary/02']}
                >
                  Submit
                </Button>
              )}
            </footer>
          </form>
        </CDialog.Content>
      </CDialog.Portal>
    </CDialog.Root>
  );
}
