import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

type Props = {
  children: React.ReactNode;
  color?: keyof Theme["colors"]["text"];
  style?: React.CSSProperties;
  marginTop?: number;
  marginBottom?: number;
  onClick?: VoidFunction;
};

const H1 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h1.fontWeight};
  font-family: ${({ theme }) => theme.font.h1.fontFamily};
  line-height: ${({ theme }) => theme.font.h1.lineHeight};
  font-size: ${({ theme }) => theme.font.h1.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const H2 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h2.fontWeight};
  font-family: ${({ theme }) => theme.font.h2.fontFamily};
  line-height: ${({ theme }) => theme.font.h2.lineHeight};
  font-size: ${({ theme }) => theme.font.h2.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const H3 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h3.fontWeight};
  font-family: ${({ theme }) => theme.font.h3.fontFamily};
  line-height: ${({ theme }) => theme.font.h3.lineHeight};
  font-size: ${({ theme }) => theme.font.h3.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const H4 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h4.fontWeight};
  font-family: ${({ theme }) => theme.font.h4.fontFamily};
  line-height: ${({ theme }) => theme.font.h4.lineHeight};
  font-size: ${({ theme }) => theme.font.h4.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const H5 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h5.fontWeight};
  font-family: ${({ theme }) => theme.font.h5.fontFamily};
  line-height: ${({ theme }) => theme.font.h5.lineHeight};
  font-size: ${({ theme }) => theme.font.h5.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const H6 = styled.h1<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.h6.fontWeight};
  font-family: ${({ theme }) => theme.font.h6.fontFamily};
  line-height: ${({ theme }) => theme.font.h6.lineHeight};
  font-size: ${({ theme }) => theme.font.h6.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 1)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 1)};
`;

const Body = styled.p<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.body.fontWeight};
  font-family: ${({ theme }) => theme.font.body.fontFamily};
  line-height: ${({ theme }) => theme.font.body.lineHeight};
  font-size: ${({ theme }) => theme.font.body.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const BodySM = styled.p<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font["body-sm"].fontWeight};
  font-family: ${({ theme }) => theme.font["body-sm"].fontFamily};
  line-height: ${({ theme }) => theme.font["body-sm"].lineHeight};
  font-size: ${({ theme }) => theme.font["body-sm"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const BodyXS = styled.p<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font["body-xs"].fontWeight};
  font-family: ${({ theme }) => theme.font["body-xs"].fontFamily};
  line-height: ${({ theme }) => theme.font["body-xs"].lineHeight};
  font-size: ${({ theme }) => theme.font["body-xs"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const Body2XS = styled.span<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font["body-2xs"].fontWeight};
  font-family: ${({ theme }) => theme.font["body-2xs"].fontFamily};
  line-height: ${({ theme }) => theme.font["body-2xs"].lineHeight};
  font-size: ${({ theme }) => theme.font["body-2xs"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const Body3XS = styled.span<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font["body-3xs"].fontWeight};
  font-family: ${({ theme }) => theme.font["body-3xs"].fontFamily};
  line-height: ${({ theme }) => theme.font["body-3xs"].lineHeight};
  font-size: ${({ theme }) => theme.font["body-3xs"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const Button = styled.span<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font.button.fontWeight};
  font-family: ${({ theme }) => theme.font.button.fontFamily};
  line-height: ${({ theme }) => theme.font.button.lineHeight};
  font-size: ${({ theme }) => theme.font.button.fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

const Label = styled.label<Props>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme }) => theme.font["body-sm"].fontWeight};
  font-family: ${({ theme }) => theme.font["body-sm"].fontFamily};
  line-height: ${({ theme }) => theme.font["body-sm"].lineHeight};
  font-size: ${({ theme }) => theme.font["body-sm"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
`;

type LinkProps = {
  size?: keyof Theme["font"];
  hoverColor?: keyof Theme["colors"]["text"];
} & Props &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;
const Link = styled.a<LinkProps & {}>`
  color: ${({ theme, color }) => theme.colors.text[color || "text/100%"]};
  font-weight: ${({ theme, size }) => theme.font[size || "body"].fontWeight};
  font-family: ${({ theme, size }) => theme.font[size || "body"].fontFamily};
  line-height: ${({ theme, size }) => theme.font[size || "body"].lineHeight};
  font-size: ${({ theme, size }) => theme.font[size || "body"].fontSize};
  margin-top: ${({ theme, marginTop }) => theme.spacing(marginTop || 0)};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme.spacing(marginBottom || 0)};
  &:hover {
    color: ${({ theme, hoverColor }) =>
      theme.colors.text[hoverColor || "text/link"]};
  }
`;

export const Text = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body,
  BodySM,
  BodyXS,
  Body2XS,
  Body3XS,
  Button,
  Link,
  Label,
};
