import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
  query {
    me {
      _id
      firstName
      lastName
      email
      profileUrl
      settings {
        timeZone
        language
        createNoteShortcut
        createTaskShortcut
        language
        timeZone
      }
    }
  }
`;

export const USER_SETTINGS = gql`
  query {
    me {
      _id
      settings {
        timeZone
        language
        createNoteShortcut
        createTaskShortcut
        language
        timeZone
      }
    }
  }
`;

export const ACTIVATED_INTEGRATIONS = gql`
  query UserIntegrations {
    userIntegrations {
      id
      type
    }
  }
`;
