import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivatePages, PublicPages } from "./pages";
import { PageTemplate } from "components";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PageTemplate />}>
        {PrivatePages.map((page, idx) => (
          <Route key={idx} {...page} />
        ))}
      </Route>
      {PublicPages.map((page, idx) => (
        <Route key={idx} {...page} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
