// src/config.js
import env from "./validateEnv";

export const config = {
  apiUrl: env.REACT_APP_API_URL,
  graphQLApiUrl: env.REACT_APP_API_URL + "/graphql",
  wsUrl: env.REACT_APP_WS_URL,
  host: env.REACT_APP_HOST_URL,
  secretKey: env.REACT_APP_SECRET_KEY,
};

export default config;
