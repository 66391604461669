import React from "react";
import { css } from "@emotion/css";
import { DownloadButton, PageLayout, Text } from "components";
import { useAuth } from "hooks";
import { theme } from "assets/theme";



// const ComingSoon = styled.div`
//   display: inline-flex;
//   transform: rotate(28deg);
//   padding: 4.58px 12.24px 4.819px 11.011px;
//   justify-content: center;
//   align-items: center;
//   border-radius: 139.13px;
//   background: #908db6;
//   position: absolute;
//   right: -20px;
//   top: 20px;
// `;

export const HomePage: React.FC = () => {
  useAuth();

  return (
    <PageLayout title="Home | Multiplayer">
      <div
        className={css`
          width: 80%;
          display: flex;
          flex-direction: column;
          margin: auto;
          @media (max-width: 1030px) {
            width: 100%;
          }
        `}
      >
        <h1
          className={css`
            font-size: 96px;
            font-family: ${theme.font["body-2xs"].fontFamily};
            text-align: center;
            background: linear-gradient(90deg, #7f77ed 0%, #aca7f6 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 0px 20.1px rgba(168, 163, 246, 0.3);
            margin-bottom: 0px;
          `}
        >
          You’re all set!
        </h1>
        <Text.Body
          style={{
            fontSize: "24px",
            color: "#C4C0F7",
            textAlign: "center",
          }}
          marginTop={3.5}
          marginBottom={0}
        >
          Your personalized meeting insights on all external attendees will
          arrive in your inbox tomorrow morning.
        </Text.Body>

        <Text.BodySM
          style={{ color: "#ACA7F6", textAlign: "center" }}
          marginTop={5.5}
        >
          Get meeting insights on the go{" "}
        </Text.BodySM>

        <DownloadButton />
      </div>
    </PageLayout>
  );
};
