import React from "react";
import * as AlertDialogComp from "@radix-ui/react-alert-dialog";
import "./styles.css";

type Props = {
  children: React.ReactNode;
  trigger: React.ReactNode;
} & AlertDialogComp.AlertDialogProps;
export const AlertDialog: React.FC<Props> = ({
  children,
  trigger,
  ...props
}) => (
  <AlertDialogComp.Root {...props}>
    <AlertDialogComp.Trigger asChild>
      <button className="Button trigger">{trigger}</button>
    </AlertDialogComp.Trigger>
    <AlertDialogComp.Portal>
      <AlertDialogComp.Overlay className="AlertDialogOverlay" />
      <AlertDialogComp.Content className="AlertDialogContent">
        {children}
      </AlertDialogComp.Content>
    </AlertDialogComp.Portal>
  </AlertDialogComp.Root>
);
