import { User } from "types";

type Params = Record<string, string>;

export function extractFromQuery<P extends object = Params>(query: string) {
  const result = {} as P;
  const str = query.startsWith("?") ? query.slice(1) : query;
  const parts = str.split("&");
  const pairs = parts.map((part) => part.split("="));

  for (const [key, value] of pairs) {
    const k = decodeURIComponent(key) as keyof P;
    const v = decodeURIComponent(value) as unknown as P[keyof P];
    result[k] = v;
  }

  return result;
}

export function displayUser(user: User) {
  return user?.firstName
    ? `${user.firstName} ${user?.lastName || ""}`
    : user && user?.email
    ? user!.email
    : null;
}
