import styled from "@emotion/styled";

type Props = {
  border?: true;
  spacing?: number;
};

const Horizontal = styled.div<Props>`
  width: 100%;
  border-bottom: ${({ border }) => (border ? 1 : 0)}px solid
    ${({ theme }) => theme.colors.accent["purple/light"]};
  margin-top: ${({ theme, spacing }) =>
    spacing ? theme.spacing(spacing) : theme.spacing(2)};
  margin-bottom: ${({ theme, spacing }) =>
    spacing ? theme.spacing(spacing) : theme.spacing(2)};
`;

const Vertical = styled.div<Props>`
  height: 100%;
  border-left: ${({ border }) => (border ? 1 : 0)}px solid
    ${({ theme }) => theme.colors.accent["purple/light"]};
  margin-top: ${({ theme, spacing }) =>
    spacing ? theme.spacing(spacing) : theme.spacing(2)};
  margin-bottom: ${({ theme, spacing }) =>
    spacing ? theme.spacing(spacing) : theme.spacing(2)};
`;

export const Separator = {
  Horizontal,
  Vertical,
};
