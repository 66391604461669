import { gql } from "@apollo/client";

export const CHANGE_USER_SETTINGS = gql`
  mutation ChangeUserSettings($settings: UserSettingsDto!) {
    changeUserSettings(settings: $settings)
  }
`;

export const REMOVE_INTEGRATION = gql`
  mutation RemoveUserIntegration($integration: RemoveIntegrationDto!) {
    removeUserIntegration(integration: $integration)
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation Mutation {
    deactivateUser
  }
`;
