import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { LogoIcon, PowerIcon, SignOutIcon } from "components/icons";
import { DeleteUserDialog, Dropdown, LogoutDialog, Text } from "components";
import { displayUser } from "utils/helpers";
import { UserContext } from "context";
import { css } from "@emotion/css";
import { ENotificationKey, E_Route } from "utils/constants";
import { theme } from "assets/theme";
import pubsub from "sweet-pubsub";
import { useMutation } from "@apollo/client";
import { DEACTIVATE_ACCOUNT } from "apollo";
import { removeTokens } from "services";

const Container = styled.div`
  width: 100%;
  /* min-width: 1000px; */
  /* width: fit-content; */
  /* overflow-x: auto; */
  padding-bottom: ${theme.spacing(2)};
  border-radius: 0px 0px ${theme.spacing(3)} ${theme.spacing(3)};
  margin: auto;
  padding-inline: 70px;
  @media (max-width: 1030px) {
    padding-inline: 20px;
  }
`;

const Header = styled.header`
  margin: auto;
  padding-top: 26px;
`;

const Body = styled.div`
  width: 100%;
  /* padding-left: calc(135px + 40px); */
  padding-top: 73px;
  margin: auto;
  position: relative;
  @media (max-width: 1030px) {
    padding-top: 40px;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
`;

const DeactivateButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoutButton: React.FC = () => {
  const [showLogout, setShowLogout] = useState(false);

  const handleLogout = () => {
    setShowLogout(true);
  };

  return (
    <div>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          width: 100%;
        `}
        onClick={handleLogout}
      >
        <SignOutIcon
          fill="#D34734"
          style={{
            height: "16px",
            width: "16px",
            marginRight: "8px",
          }}
        />

        <Text.BodySM
          marginBottom={0}
          marginTop={0}
          style={{ color: "#D34734" }}
        >
          Log Out
        </Text.BodySM>
      </div>

      <LogoutDialog open={showLogout} onOpenChange={setShowLogout} />
    </div>
  );
};

type UserProfileProps = {
  profileUrl?: string;
  userName?: string;
};
export const UserProfile: React.FC<UserProfileProps> = ({
  profileUrl,
  userName,
}) => (
  <div
    className={css`
      background: #181823;
      border-radius: 200px;
      display: inline-flex;
      padding: 7px 38px 7px 8px;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    `}
  >
    <div
      className={css`
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 24px;
        /* background: url(${profileUrl}),
          lightgray 0px -3.305px / 100% 150% no-repeat; */
      `}
    >
      <img
        src={
          profileUrl ||
          "https://storage.googleapis.com/multiplayer-images-public/1675974052439-200w.gif"
        }
        width="100%"
        height="100%"
        alt="profile"
      />
    </div>

    <Text.Body2XS marginBottom={0} marginTop={0}>
      {userName || "Anonymous"}
    </Text.Body2XS>
  </div>
);

export const PageTemplate: React.FC = () => {
  const { state: userState } = useContext(UserContext);
  const navigate = useNavigate();

  const [deactivateAccount] = useMutation(DEACTIVATE_ACCOUNT);

  const handleDeactivateAccount = () => {
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    deactivateAccount()
      .then(() => {
        removeTokens();
        navigate(E_Route.AuthBegin);
      })
      .catch();
  };

  const handleCancel = () => {
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
  };

  //   const [updateUser] = useMutation(UPDATE_USER);

  //   useEffect(() => {
  //     const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     updateUser({ variables: { timeZone: time } });
  //   }, [updateUser]);
  return (
    <Container>
      <Header>
        <Nav>
          <a href={E_Route.Home}>
            <LogoIcon style={{ width: "40px", height: "32px" }} />
          </a>

          <Dropdown.Root
            triggerComponent={
              <UserProfile
                userName={displayUser(userState)!}
                profileUrl={userState?.profileUrl}
              />
            }
          >
            <div
              className={css`
                width: 179px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-inline: 18px;
              `}
            >
              <LogoutButton />
            </div>

            <div
              className={css`
                width: 195px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px 8px 0px 16px;
              `}
            >
              <div
                className={css`
                  margin-bottom: 16px;
                `}
              />
              <DeleteUserDialog
                onCancel={handleCancel}
                onConfirm={handleDeactivateAccount}
              >
                <DeactivateButton>
                  <PowerIcon
                    fill="#D34734"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "8px",
                    }}
                  />
                  <Text.BodySM
                    marginBottom={0}
                    marginTop={0}
                    style={{ color: "#D34734" }}
                  >
                    Deactivate account
                  </Text.BodySM>
                </DeactivateButton>
              </DeleteUserDialog>
            </div>
          </Dropdown.Root>
        </Nav>
      </Header>

      <Body>
        <Outlet />
      </Body>
    </Container>
  );
};
