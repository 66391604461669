import React, { useContext, useEffect } from "react";
import { LogoFullIcon } from "components/icons";
import styled from "@emotion/styled";
import { LoadingBar, Text } from "components";
import { useNavigate } from "react-router-dom";
import { extractFromQuery } from "utils/helpers";
import { UserContext } from "context";
import * as crypto from "crypto-js";
import { E_UserAction } from "types";
import { saveTokens } from "services";
import config from "config/config";
import { css } from "@emotion/css";

const Container = styled.div`
  /* position: absolute;
  top: 0px;
  left: 0px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  overflow: hidden;
  @media (max-width: 1030px) {
    /* justify-content: center; */
    padding-top: 100px;
    padding-inline: 10px;
  }
`;

type Params = {
  data: string;
};

export const ContinuePage = React.memo(() => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);

  useEffect(() => {
    const { data } = extractFromQuery<Params>(window.location.search);
    if (data) {
      const decryptedData = crypto.AES.decrypt(data, config.secretKey).toString(
        crypto.enc.Utf8
      );
      const userData = JSON.parse(decryptedData);
      if (userData?.token?.session) {
        saveTokens(userData?.token?.session, userData.token.refresh);
        dispatch({ type: E_UserAction.SET_USER, payload: userData.user });
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    }
  }, [dispatch, navigate]);

  return (
    <Container>
      <LogoFullIcon style={{ height: "36px", marginBottom: "175px" }} />
      <Text.H1
        className={css`
          @media (max-width: 1030px) {
            font-size: 30px !important;
          }
        `}
      >
        Welcome!
      </Text.H1>
      <Text.H2
        className={css`
          @media (max-width: 1030px) {
            font-size: 18px !important;
          }
        `}
      >
        Loading your Multiplayer experience.
      </Text.H2>
      <LoadingBar duration={2500} />
    </Container>
  );
});
