import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Text } from "components";
import {
  CheckCircleIcon,
  PlugsConnectedIcon,
  PlugsIcon,
  PlusIcon,
} from "components/icons";
import React from "react";

type Props = {
  title: string;
  description: string;
  Icon: React.FC<any>;
  completed: boolean;
  helperText?: string;
  disableButtonHelperText?: string;
  isIntegration?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onRemove: VoidFunction;
};

const Add = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary["primary/03"]};
  display: flex;
  padding: 12px 16px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Remove = styled.div`
  background-color: ${({ theme }) => theme.colors.primary["primary/03"]};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 12px 16px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IntegrationItem: React.FC<Props> = ({
  title,
  description,
  Icon,
  completed,
  helperText,
  disableButtonHelperText,
  isIntegration,
  onClick,
  onRemove,
}) => {
  const addComponent = (
    <Add onClick={onClick}>
      {isIntegration ? (
        <PlugsIcon
          fill="#fff"
          style={{ width: "24px", height: "24px", marginRight: "12px" }}
        />
      ) : (
        <PlusIcon
          fill="#fff"
          style={{ width: "24px", height: "24px", marginRight: "12px" }}
        />
      )}
      <Text.Body2XS>{isIntegration ? "Connect" : "Enable"}</Text.Body2XS>
    </Add>
  );

  const removeComponent = (
    <Remove onClick={isIntegration ? onRemove : onRemove}>
      {isIntegration ? (
        <PlugsConnectedIcon
          fill="#fff"
          style={{ width: "24px", height: "24px", marginRight: "12px" }}
        />
      ) : (
        <CheckCircleIcon
          fill="#fff"
          style={{ width: "24px", height: "24px", marginRight: "12px" }}
        />
      )}
      <Text.Body2XS>{isIntegration ? "Connected" : "Enabled"}</Text.Body2XS>
    </Remove>
  );
  return (
    <div
      className={css`
        width: 100%;
        height: 72px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #272539;
        padding: 8px 8px;
      `}
    >
      <div
        className={css`
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        <div
          className={css`
            width: 56px;
            height: 56px;
            border-radius: 7px;
            background: #272539;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Icon
            style={{
              width: "32px",
              height: "32px",
              borderRadius: title.includes("Read") ? "8px" : "",
            }}
          />
        </div>
        <div
          style={{
            marginLeft: "20px",
            height: "42px",
          }}
        >
          <Text.Body marginBottom={0} marginTop={0}>
            {title}
          </Text.Body>
          <Text.BodySM color="text/75%" marginTop={0.5}>
            {description}
          </Text.BodySM>
        </div>
      </div>

      {completed ? removeComponent : addComponent}
    </div>
  );
};
