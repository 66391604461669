import React from "react";
import { css } from "@emotion/css";
import { PageLayout } from "components";

export const NotFoundPage: React.FC = () => {
  return (
    <PageLayout title="Home | Multiplayer">
      <div
        className={css`
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        Not found
      </div>
    </PageLayout>
  );
};
