import { z } from "zod";

const envSchema = z.object({
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_HOST_URL: z.string().url(),
  REACT_APP_WS_URL: z.string().url(),
  REACT_APP_SECRET_KEY: z.string(),
  NODE_ENV: z.string(),
});

// Parse and validate the environment variables
const env = envSchema.safeParse(process.env);

if (!env.success) {
  console.error("❌ Invalid environment variables:", env.error.format());
  throw new Error(`❌ Invalid environment variables: ${env.error.format()}`);
}

export default env.data;
