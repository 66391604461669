import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";

const dotCount = 5;

type Props = {
  className?: string;
  noCover?: boolean;
};

export const PageLoading: React.FC<Props> = ({ className, noCover }) => {
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevActiveDot) => (prevActiveDot + 1) % dotCount);
    }, 300); // interval of 500ms for each dot

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={css`
        width: ${noCover ? "" : "100%"};
        height: ${noCover ? "" : "100%"};
        min-height: ${noCover ? "" : "70vh"};
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
      `}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        {Array.from({ length: dotCount }).map((_, idx) => (
          <div
            key={idx}
            style={{
              width: "11.25px",
              height: "18px",
              borderRadius: "50px",
              background: idx === activeDot ? "#6A53FE" : "#453E69",
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};
