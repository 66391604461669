// import axios from "axios";
// import config from "config/config";
import Cookies from "js-cookie";
import { STORAGE_KEY } from "utils/constants";

// const API_URL = config.apiUrl;

// interface TokenResponse {
//   accessToken: string;
//   refreshToken: string;
// }

export const getAccessToken = (): string | null => {
  return (
    Cookies.get(STORAGE_KEY.ACCESS_TOKEN) ||
    localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)
  );
};

export const getRefreshToken = (): string | null => {
  return (
    Cookies.get(STORAGE_KEY.REFRESH_TOKEN) ||
    localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
  );
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
  localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
  Cookies.set(STORAGE_KEY.ACCESS_TOKEN, accessToken);
  Cookies.set(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
};

export const removeTokens = (): void => {
  Cookies.remove(STORAGE_KEY.REFRESH_TOKEN);
  localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);

  Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
};

// const isTokenExpiring = (token: string): boolean => {
//   const payload = JSON.parse(atob(token.split(".")[1]));
//   const expirationTime = payload.exp * 1000; // Convert to milliseconds
//   const currentTime = Date.now();
//   return expirationTime - currentTime < 60000; // Token expires in less than 1 minute
// };

// const requestNewToken = async (): Promise<TokenResponse | undefined> => {
//   const refreshToken = getRefreshToken();
//   if (!refreshToken) {
//     return;
//   }

//   try {
//     const response = await axios.post<TokenResponse>(
//       `${API_URL}/auth/refresh-token`,
//       {
//         refreshToken,
//       }
//     );

//     if (response.status !== 200) {
//       return;
//     }

//     return response.data;
//   } catch (error: any) {
//     if (error.response.status === 401 || error.response.status === 403) {
//       removeTokens();
//     }
//     return;
//   }
// };

export const getNewToken = async (): Promise<string | undefined> => {
  let accessToken = getAccessToken();

  // if (!accessToken || isTokenExpiring(accessToken)) {
  //   const tokens = await requestNewToken();
  //   if (!tokens) {
  //     removeTokens();
  //     return;
  //   }
  //   const { accessToken: newAccessToken, refreshToken } = tokens;
  //   saveTokens(newAccessToken, refreshToken);
  //   accessToken = newAccessToken;
  // }

  return accessToken || "";
};
